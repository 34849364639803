<template>
  <div>
    <the-app-bar />
    <div class="row oonpay-bg">
      <div class="col-md-12 text-center  py-32">
        <h3 class="text-3xl text-white fontsemibold">
          Our Global Direct Payment Network Locations
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h4
          class="text-3xl  font-semibold bg-gradient-to-r from-yellow-500 py-6 pl-12 text-white"
        >
          Send to the following Coutries
        </h4>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h3>AFRICA</h3>
          <div class="scroll-menu mb-5">
            <ul>
              <li v-for="(country, index) in africa" :key="index">
                <a @click="showMap(index)">{{ country }}</a>
              </li>
            </ul>
          </div>

          <h3>ASIA</h3>
          <div class="scroll-menu">
            <ul>
              <li v-for="(country, index) in asia" :key="index">
                <a href="#">{{ country }}</a>
              </li>
            </ul>
          </div>

          <h3>SOUTH AMERICA</h3>
          <div class="scroll-menu">
            <ul>
              <li v-for="(country, index) in southamerica" :key="index">
                <a href="#">{{ country }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <img
            v-if="map == 0"
            src="../../assets/images/map/Ghana_Regional_Map.jpg"
            alt=""
          />

          <img
            v-if="map == 1"
            src="../../assets/images/map/Nigeria locations.png"
            alt=""
          />

          <img
            v-if="map == 2"
            src="../../assets/images/map/Ghana_Regional_Map.jpg"
            alt=""
          />

          <img
            v-if="map == 3"
            src="../../assets/images/map/Ghana_Regional_Map.jpg"
            alt=""
          />

          <img
            v-if="map == 4"
            src="../../assets/images/map/administrative-map-of-kenya-max.jpg"
            alt=""
          />

          <img
            v-if="map == 5"
            src="../../assets/images/map/administrative-divisions-map-of-uganda-min.jpg"
            alt=""
          />

          <img
            v-if="map == 6"
            src="../../assets/images/map/somalia-physical-map-max.jpg"
            alt=""
          />

          <img
            v-if="map == 7"
            src="../../assets/images/map/administrative-map-of-ethiopia-max.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h4
          class="text-3xl  font-semibold bg-gradient-to-r from-yellow-500 py-6 pl-12 text-white"
        >
          Send From the following Coutries
        </h4>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h3>EUROPE</h3>
          <div class="scroll-menu mb-5">
            <ul>
              <li v-for="(country, index) in europe" :key="index">
                <a @click="showMap()">{{ country }}</a>
              </li>
            </ul>
          </div>

          <h3>NORTH AMERICA</h3>
          <div class="scroll-menu">
            <ul>
              <li v-for="(country, index) in northamerica" :key="index">
                <a href="#">{{ country }}</a>
              </li>
            </ul>
          </div>

          <h3>AUSTRALIA</h3>
          <div class="scroll-menu">
            <ul>
              <li v-for="(country, index) in australia" :key="index">
                <a href="#">{{ country }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-8">
          <img
            v-if="map == 0"
            src="../../assets/images/map/dreamstime.jpg"
            alt=""
          />

          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
import Footer from "../../components/footer.vue";

export default {
  components: { TheAppBar, Footer },
  data() {
    return {
      map: 0,
      africa: [
        "Ghana",
        "Nigeria",
        "Cameroon",
        "Cote D'Ivoire",
        "Kenya",
        "Uganda",
        "Somalia",
        "Ethopia"
      ],
      asia: ["India", "Philipians"],
      southamerica: ["Mexico"],
      europe: ["United Kingdom", "Italy", "Germany", "Belgium"],
      northamerica: ["USA", "Canada"],
      australia: ["Australia"]
    };
  },
  methods: {
    showMap(i) {
      this.map = i;
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-menu {
  height: 150px; /* Set a fixed height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}

.oonpay-bg {
  background: #0c4369;
}

/* Style for menu items */
.scroll-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scroll-menu li {
  margin: 0;
}

.scroll-menu a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.scroll-menu a:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}
</style>
